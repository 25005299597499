form {
    .form-customer {
        .element-form-customer {
            margin-top: 30px;
            margin-bottom: 0;
        }

        input.fl-input, select.fl-select, textarea.fl-textarea {
            margin-bottom: 0;
        }

        .error-form-mp {
            margin-top: 0px;
            margin-bottom: 0px;
        }
    }

    .bloc-naf {
        margin-bottom: 24px;
    }
}

body {
    &.customer-page {
        .box-header {
            .pointer {
                cursor: pointer;
                display: block;
                margin: -10px;
                padding: 10px;
            }
        }
        .bloc {
            &-general-information {
                .box-body__row {
                    margin-bottom: 7px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
            &-preferences-employeur {
                &__row {
                    margin-bottom: 7px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
            &-contacts {
                .datatable {
                    text-align: center;
                    .btn {
                        max-width: 48%;
                        display: inline-block;
                        margin-right: 1%;
                        padding: 6px 10px;
                    }
                }
                .box-tools {
                    margin-bottom: 10px;
                }
            }
            &-comments {
                .box-body {
                    .icon-action {
                        font-size: 11px;
                        padding: 5px 5px 4px 5px;
                    }
                }
                .list-comments {
                    margin-top: 30px;

                    .bloc-list-comments {
                        margin-top: 25px;

                        .box-comments {
                            background: transparent;
                            margin-top: 20px;

                            .comment-text {
                                margin-top: -25px;
                                color: $mainLogoDarkColor;

                                .username {
                                    color: $mainLogoDarkColor;
                                }
                            }
                        }
                    }
                }
            }
            &-tarifs {
                .nav-tarifs {
                    background-color: $colorBoxHeaderRed;
                    color: #ffffff;
                    border-top-left-radius: 3px;
                    border-top-right-radius: 3px;
                    border-bottom-width: 0;

                    &__item {
                        &__link {
                            color: #ffffff;
                            padding-left: 7px;
                            padding-right: 7px;

                            &:hover {
                                color: #ffffff;
                                font-weight: bold;
                                text-decoration: underline;
                            }

                            &.noclick {
                                font-weight: bold;

                                &:hover {
                                    color: #ffffff;
                                    text-decoration: none;
                                }

                                &:focus {
                                    background-color: transparent;
                                }
                            }
                        }
                        &.active {
                            border-top-color: transparent;
                            color: $mainLogoDarkColor;

                            a {
                                &:hover {
                                    color: $mainLogoDarkColor;
                                    font-weight: normal;
                                    text-decoration: none;
                                }
                            }
                        }
                    }
                }
                .tab-tarif {
                    padding-top: 10px;

                    .bloc-tarif {
                        display: flex;

                        .email-customer-order-form, .generate-order-form {
                            display: inline-block;
                        }

                        .tab-deleted-packs {
                            margin-top: 14px;
                        }

                        .tarif-cm-pack-no-data-left {
                            margin: auto;
                            text-align: center;
                        }

                        .tarif-cm-pack-no-data-right {
                            margin: auto;
                        }
                    }
                }

                &.child-collapse {
                    .nav-tarifs {
                        &__item {
                            &.active {
                                .nav-tarifs__item__link {
                                    border: 0;
                                    background: transparent;
                                    color: #ffffff;
                                }
                            }
                        }
                    }
                }
            }
            &-tarif {
                .radio label, .checkbox label {
                    padding-left: 0;
                }
                &__nav {
                    &__item {
                        color: inherit;
                    }
                }
            }
            &-arboresence {
                .btn {
                    white-space: normal;
                    margin-bottom: 5px;
                }

                .group-important-action {
                    color: red;
                }
            }
            &-comments {
                .act-info {
                    &__dd {
                        margin-left: 5px;
                    }
                }
            }
        }
        .infos-doctors-mandate {
            .element-doctor {
                margin-top: 20px;
                padding: 10px;
                border: 1px solid #757575;
                box-shadow: 12px 2px 20px rgba(0, 0, 0, 0.5);
                -moz-box-shadow: 12px 2px 20px rgba(0, 0, 0, 0.5);
                -webkit-box-shadow: 12px 2px 20px rgba(0, 0, 0, 0.5);

                .name {
                    font-size: 20px;
                    text-align: center;
                }

                dt {
                    width: 130px;
                }
            }
        }
        .main-row {
            display: flex;
            flex-wrap: wrap;
            &__item {
                flex-basis: 100%;
                @for $i from 1 through 5 {
                    &:nth-child(#{$i}) {
                        order: $i;
                    }
                }

                .spinner-note{
                    height: 20px;
                    width: 20px;
                }

                .btn-save-note {
                    margin-right: 10px;
                    border-radius: 5px;
                }

                textarea{
                    background-color: $colorTextAreaBlocNote;
                }
            }
            .bloc-tarif {
                .radio label, .checkbox label {
                    padding-left: 0;
                }
                dd {
                    text-align: right;
                }
            }
        }
        .alert-customer-financial-health {
            margin-bottom: 20px;
            &.rj {
                background-color: $colorFinancialHealthRj !important;
            }
            &.other {
                background-color: $colorFinancialHealthOther !important;
            }
        }
        .liste-contact {
            overflow: auto;
            &__table {
                thead {
                    th {
                        text-align: center;
                    }
                }
            }
        }

        @include respond-to(large) {
            .main-row {
                &__item {
                    flex-basis: 50%;
                    @for $i from 1 through 5 {
                        &:nth-child(#{$i}) {
                            order: $i;
                        }
                    }
                }
            }
        }
    }
    &.customer-list-page {
        .customer-disabled {
            opacity: 0.5;
        }
    }
}
