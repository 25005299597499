.datatable{
  width: 100% !important;

  td:last-child{
    width: 100px;
  }
  td:first-child{
    width: 50px;
  }
}

.datatable-ged{
  width: 100% !important;

  td:last-child{
    width: 100px;
  }
  td:first-child{
    width: 25vh;
  }
}