.calendar-box {
  background: #FFFFFF;
  color: $mainLogoDarkColor;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  &:hover {
    color: lighten($mainLogoDarkColor, 10)
  }

  .calendar-title {
    color: $mainLogoDarkColor;
    display: inline;
  }

  tfoot {
    &:hover {
      color: darken($mainLogoGoldColor, 10)
    }
  }

  th {
    &:hover {
      color: darken($mainLogoGoldColor, 10)
    }
  }
}
