/**
 * Fiche medecin
 */

body {
    &.doctor-page {
        @import "common_doctor_control";

        .honoraires {
            &__table{
                &__label {
                    display: inline;
                }

                &__row {
                    &_inactive {
                        opacity: 0.65;
                    }
                }
                &__title{
                    margin-top: 10px;
                    font-size: 16px;
                    text-align: center;
                    text-decoration: underline;
                }
                td {
                    &:last-child {
                        width: auto;
                    }
                    &:first-child{
                        width: auto;
                        max-width: 110px;
                    }
                }
            }
        }
        #slider-blue .slider-selection {
            background: $mainLogoDarkColor;
        }
        .box {
            &-suivi{
                &__date {
                    display: inline;
                    margin-left: 10px
                }
            }
            &-planning {
                margin-top: 20px;
                .fc-center {
                    margin-top: 10px;
                    float: right;
                }
            }
        }
        .table-fees {
            margin-top: 20px;

            &__column-fixed-width {
              width: 17%;
            }

            &__field-supplement {
              margin-top: 10px;
            }

            th {
              text-align: center;
            }

            td {
                .element-form-customer:first-child {
                    margin-top: 5px;
                }
            }
        }
        .blank-reporting {
            min-height: 100%;
            background-color: #FFFFFF;
        }
        .doctor-info {
            &__row {
                margin-bottom: 10px;
            }
            &__picto {
                font-size: 1.5em;
            }
            &__convention-edit {
                &__input {
                    margin-bottom: 10px;
                }
            }
        }
        .bloc-preferences{
            .doctor-full-table{
                p{
                    margin-bottom: 0;
                }
            }
        }
        .container-convention {
            &__field {
                width: 90%;
            }

            &__display-check-convention {
                i {
                    font-size: 17px;
                    margin-right: 10px;
                }
            }
        }

        .spinner-note{
            height: 20px;
            width: 20px;
        }

        .btn-save-note {
            margin-right: 10px;
            border-radius: 5px;
        }

        textarea{
            background-color: $colorTextAreaBlocNote;
        }
    }
    &.doctor-list-page {
        .doctor-disabled {
            opacity: 0.5;
        }
    }
}

@include respond-to(extra-large) {
    body {
        &.doctor-page {
            .box{
                &-planning {
                    .fc-center {
                        margin-top: 0;
                        float: none;
                    }
                }
            }
        }
    }
}


