body {

	&.sidebar-collapse {
		.main-sidebar {
			.user-panel {
				.imgae {
					flex-basis: 100%;
				}
			}
			.li-left-bar{
				background-color: $colorBoxHeaderImportant;
			}
		}
	}

	.main-sidebar {
		.user-panel {
			display: flex;
			align-items: center;
			white-space: normal;
			.imgae {
				flex-basis: 30%;
			}
			.info {
				position: initial;
				left: auto;
				flex-basis: 70%;
				white-space: initial;
				line-height: 20px;
				p {
					margin-bottom: 0;
				}
			}
		}
	}

	.main-header {
		.sidebar-toggle {
			&:hover, &:focus, &:active {
				color: #fff;
				text-decoration: none;
			}
		}
	}

	.icon-action {
		display: inline-block;
		border-radius: 60px;
		background-color: $colorIconEdit;
		padding: 7px 7px 7px 8px;
		color: #ffffff;
		
		&.icon-remove {
			background-color: $colorIconRemove;
		}
		
		&.icon-see {
			background-color: $mainColorBlue;
		}
		
		&.noclick {
			font-size: 10px;
			padding: 4px 4px 4px 6px;
			
			&.plus {
				padding: 3px 4px 3px 4px;
			}
		}
	}
	
	.no-decoration {
		&:hover, &:focus, &:active {
			text-decoration: none !important;
		}
	}
	
	.no-pointer {
		cursor: initial;
	}
	
	.select-multiple {
		color: #444;
		border-radius: 0 !important;
		position: relative;
		cursor: pointer;
		-webkit-appearance: none;
		-moz-appearance: none;
		width: 100%;
		outline: 0;
		font-size: 16px;
		line-height: 1.5;
		border: 1px solid #dfdfdf !important;
		background-color: #fff;
		box-sizing: border-box;
		transition: all .2s ease-in-out;
		margin-bottom: 24px;
		box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
		-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
	}
	
	.launch-tuto {
		cursor: pointer;
	}
	
	/* Debut Medical Control */
	.medical-control-icons {
		font-size: 20px;
		margin-left: 5px;
	}
	/* Fin Medical Control */
	
	/* Slider doctor */
	#slider-blue .slider-selection {
		background: $mainLogoDarkColor;
	}
	/* Fin Slider doctor */
	
	.dt-icon {
		font-size: 25px;
	}
	
	.pointer {
		cursor: pointer;
	}
	
	.cancel-padding {
		padding: 0px !important;
	}
	
	.error-form-mp {
		margin-top: -24px;
		margin-bottom: 20px;
	}
	
	.label-float-labels {
		opacity: 1 !important;
		visibility: visible !important;
		display: block;
		top: 17px;
		font-size: 12px;
		font-weight: 400;
		line-height: 1;
		color: #bbb;
		border-radius: 0px;
		transition: all .2s ease-in-out;
		z-index: 1;
	}
	
	.white-text {
		color: #fff;
	}
	
	.hide-element {
		display: none;
	}
	
	.radio label, .checkbox label {
		padding-left: 0;
	}
	
	.select2-selection__arrow {
		display: none;
	}
	
	.input-disabled {
		background-color: #eee !important;
	}
	
	.slider-handle {
		&.min-slider-handle {
			&.round {
				margin-top: 0;
			}
		}
	}

	.checked-green {
		color: #73c06b;
	}

	.not-checked-red {
		color: #c0836b;
	}

    .box-collapse {
        .box-body {
            min-height: 0 !important;
        }
    }

	.box-header{
		.box-tools{
			top: 50%;
			transform: translateY(-50%);
		}
	}

	.box-body {
		&__filtres-btn {
			padding: 0 15px;
			&__button {
				font-size: 1em;
			}
		}
		&__inactive {
			padding: 0 15px;
		}
	}

	.datatable{
        &.collapsed{
            tr:not(.child){
                td{
                    &:first-child{
                        cursor: pointer;
                        padding-left: 30px;
                        position: relative;
                        &:before{
                            top: 50%;
                            left: 4px;
                            transform: translateY(-50%);
                            font-size: 14px;
                            display: block;
                            position: absolute;
                            color: $colorBtnSuccess;
                            text-align: center;
                            text-indent: 0;
                            content: "\f13a";
                            font: normal normal normal 14px/1 FontAwesome;
                        }
                    }
                }
                &.parent{
                    td {
                        &:first-child {
                            &:before{
                                color: $colorBtnDanger;
                                content:"\f139"
                            }
                        }
                    }
                }
            }
        }
        .dtr {
            &-details{
                list-style: none;
                text-align: left;
                padding: 0;
            }
            &-title{
                text-decoration: underline;
                width: 20%;
                display: inline-block;
            }
        }
    }
	.city-list {
		list-style: none;
		position: absolute;
		background: white;
		width: 100%;
		z-index: 2;
		top: 33px;
		padding:0;
		border: 1px solid $colorBorderInput;
		max-height: 400px;
		overflow-y: scroll;
		&__item{
			padding: 5px 8px;
			line-height: normal;
			cursor: pointer;
			&:hover{
				background: $colorBgText;
			}
		}
	}

	[required="required"], .required {
		border-color: $colorRequired !important;
	}

	.cancel-margin-top {
		margin-top: 0 !important;
	}

	.page-title {
		display: flex;
		align-items: center;
		&__text {
			background: $colorBoxHeaderImportant;
			padding: 8px 30px;
			border-radius: 25px;
			text-transform: uppercase;
			color: white;
			margin-left: 15px;
			padding-bottom: 4px;
		}
		&__picto {
			background: $colorBoxHeaderImportant;
			height: 65px;
			line-height: 65px;
			border-radius: 100px;
			text-align: center;
			color: white;
			width:80px;
			display: flex;
			align-items: center;
			justify-content: center;
			&:before {
				font-size: 1.6em;
			}
		}
		&__tuto {
			margin-left: 10px;
		}
	}

	.red-amount {
		font-weight: bold;
		color: #FE0101;
	}

	.green-amount {
		font-weight: bold;
		color: #00dc07;
	}

	.near-zero-text {
		color: $colorIconRemove;
		font-weight: 800;
	}
}
