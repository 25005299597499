.icheckbox_square-green,
.iradio_square-green {
  display: inline-block;
  *display: inline;
  vertical-align: middle;
  margin: 0;
  padding: 0;
  width: 22px;
  height: 22px;
  background: url(../vendors/icheck/img/green.png) no-repeat;
  border: none;
  cursor: pointer;
}

.icheckbox_square-green {
  background-position: 0 0;
}
.icheckbox_square-green.hover {
  background-position: -24px 0;
}
.icheckbox_square-green.checked {
  background-position: -48px 0;
}
.icheckbox_square-green.disabled {
  background-position: -72px 0;
  cursor: default;
}
.icheckbox_square-green.checked.disabled {
  background-position: -96px 0;
}

.iradio_square-green {
  background-position: -120px 0;
}
.iradio_square-green.hover {
  background-position: -144px 0;
}
.iradio_square-green.checked {
  background-position: -168px 0;
}
.iradio_square-green.disabled {
  background-position: -192px 0;
  cursor: default;
}
.iradio_square-green.checked.disabled {
  background-position: -216px 0;
}

/* Retina support */
@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
only screen and (-moz-min-device-pixel-ratio: 1.5),
only screen and (-o-min-device-pixel-ratio: 3/2),
only screen and (min-device-pixel-ratio: 1.5) {
  .icheckbox_square-green,
  .iradio_square-green {
    background-image: url(../vendors/icheck/img/green@2x.png);
    -webkit-background-size: 240px 24px;
    background-size: 240px 24px;
  }
}