.orgchart-container {
  text-align: center;
  overflow-x: auto;

  .orgchart {
    background-image: none;
    padding: 0;

    .spinner {
      color: $mainLogoGoldColor;
    }

    .lines {
      .topLine {
        border-top-color: $mainLogoGoldColor;
      }

      .rightLine {
        border-right-color: $mainLogoGoldColor;
      }

      .leftLine {
        border-left-color: $mainLogoGoldColor;
      }

      .downLine {
        background-color: $mainLogoGoldColor;
      }
    }

    .node {
      &:hover {
        cursor: pointer;
      }

      .title {
        background-color: $mainLogoDarkColor;
        color: $mainLogoGoldColor;
        height: 40px;

        &.current-customer {
          background-color: $mainLogoGoldColor;
          color: $mainLogoDarkColor;
        }
      }

      .invoices {
        color: $mainLogoDarkColor;
      }
    }
  }
}