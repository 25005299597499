.login-page {
	background-color: $mainLogoDarkColor !important;
	.login-logo {
		img {
			margin-top: 140px;
		}
	}
	.login-box {
		margin: 0 auto;
	}
}

.main-header {
	* {
		color: #ffffff;
	}
	.logo {
		background-color: $mainLogoDarkColor;
	}
	.navbar {
		background-color: $mainColorBlue;
		
		li {
			& > a {
				&:hover, &:active, &:focus {
					background-color: $mainColorDark;
				}
			}
		}
		
		.dropdown {
			&.user {
				.dropdown-menu {
					.user-header {
						background-color: $mainColorDark;
					}
				}
			}
		}
	}
}

.main-sidebar {
	background-color: $mainColorDark;
	* {
		color: #ffffff;
	}
	.sidebar-menu {
		.treeview {
			& > a {
				background-color: $mainLogoDarkColor;
			}
			
			.treeview-menu {
				min-width: 300px;
				background-color: $mainColorBlue;
				li {
					&.active {
						text-decoration: underline;
					}
				}

			}
			
			a {
				text-decoration: none;
				
				&:hover {
					color: #ffffff;
					text-decoration: underline;
				}
			}
			
			.treeview-self-inside {
				& > a {
					background-color: transparent;
				}
			}
		}
		
		& > li {
			background-color: $mainLogoDarkColor;
			
			&.active {
				text-decoration: underline;
			}
		}
	}
	.sidebar-toggle{
		&:hover{
			text-decoration: none;
		}
	}
}

.content-wrapper {
	color: $mainColorDark;
	.box-mp {
		border: none !important;
		border-radius: 3px;
		.box-header {
			background: $colorBoxHeaderBasic;
			color: white;
			&.box-header-important {
				background: $colorBoxHeaderImportant;
			}
			&.box-header-red {
				background: $colorBoxHeaderRed;
			}
			&.box-header-note {
				background: $colorBoxHeaderBlocNote;
			}
			&.box-header-light {
				background: $colorBoxHeaderLight;
			}
			.box-title {
				a {
					color: $mainColorDark;
					text-decoration: none;
					
					&:hover {
						text-decoration: none;
					}
				}
			}
		}
		.box-body {
			padding: 20px;
			min-height: 100px;
		}
		.overlay {
			border-radius: 0;
		}
	}
	.nav-tabs-custom {
		margin-bottom: 0 !important;
		
		.tab-content {
			//padding: 10px;
			padding: 15px;
			overflow-y: auto;
			overflow-x: hidden;
		}
	}
}