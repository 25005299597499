@charset "UTF-8";

@font-face {
	font-family: Comfortaa;
	font-style: normal;
	font-weight: 400;
	src: url("../fonts/Comfortaa_Regular.ttf");
}

@font-face {
	font-family: Comfortaa;
	font-style: normal;
	font-weight: 700;
	src: url("../fonts/Comfortaa_Bold.ttf");
}

@font-face {
	font-family: "medicatpartner";
	src:url("../fonts/medicatpartner.eot");
	src:url("../fonts/medicatpartner.eot?#iefix") format("embedded-opentype"),
	url("../fonts/medicatpartner.woff") format("woff"),
	url("../fonts/medicatpartner.ttf") format("truetype"),
	url("../fonts/medicatpartner.svg#medicatpartnet") format("svg");
	font-weight: normal;
	font-style: normal;
}

[data-icon] {
	font-family: "medicatpartner" !important;
	content: attr(data-icon);
	font-style: normal !important;
	font-weight: normal !important;
	font-variant: normal !important;
	text-transform: none !important;
	speak: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

[class^="picto-"],
[class*=" picto-"] {
	font-family: "medicatpartner" !important;
	font-style: normal !important;
	font-weight: normal !important;
	font-variant: normal !important;
	text-transform: none !important;
	speak: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	display: inline-block;
}

$pictoMinus: "\62";

%picto-minus{
	content: $pictoMinus;
}

.picto-fa-pencil-square-o:before {
    content: "\61";
}
.picto-fa-minus-square:before {
    content: $pictoMinus;
}
.picto-fa-check-square:before {
    content: "\63";
}
.picto-fa-plus-square:before {
    content: "\64";
}
.picto-fa-user:before {
    content: "\65";
}
.picto-fa-phone:before {
    content: "\66";
}
.picto-fa-home:before {
    content: "\67";
}
.picto-fa-times-circle:before {
    content: "\68";
}
.picto-fa-street-view:before {
    content: "\69";
}
.picto-fa-plus:before {
    content: "\6a";
}
.picto-fa-calendar:before {
    content: "\6b";
}
.picto-fa-envelope:before {
    content: "\6c";
}
.picto-fa-print:before {
    content: "\6d";
}
.picto-fa-fax:before {
    content: "\6e";
}
.picto-fa-hand-o-right:before {
    content: "\6f";
}
.picto-fa-paperclip:before {
    content: "\70";
}


