.satisfaction {
  font-size: 25px;
  font-weight: bold;

  &.middle-satisfaction {
    color: $colorMiddle;
  }

  &.bad-satisfaction {
    color: $colorBad;
  }

  &.good-satisfaction {
    color: $colorGood;
  }
}

// Custom radio buttons
form {
  .input-smiley {
    margin-bottom: 35px;
    text-align: center;

    .radio {
      label {
        padding-left: 0;
      }

      display: inline-block;
      margin-left: 10px;
      width: 40px;
      padding-top: 0;

      input[type="radio"] {
        -webkit-appearance: none;
        -moz-appearance: none;
        width: auto;
        height: auto;
        border: none;
        cursor: pointer;
        margin: 0 -25px;

        &:focus {
          outline: 0;
        }

        font-size: 40px;

        &.good {
          color: $colorGood;

          &:before {
            content: "\f118";
            font-family: FontAwesome;
            font-style: normal;
            font-weight: normal;
            text-decoration: inherit;
          }

          &:hover, &:checked {
            &:before {
              color: white;
              background-color: $colorGood;
              border-radius: 100px;
              width: 40px;
              display: block;
              text-align: center;
              padding-left: 1.5px;
              padding-right: 0.5px;
              padding-top: 0.5px;
            }
          }
        }

        &.medium {
          color: $colorMiddle;

          &:before {
            content: "\f11a";
            font-family: FontAwesome;
            font-style: normal;
            font-weight: normal;
            text-decoration: inherit;

          }

          &:hover, &:checked {
            &:before {
              color: white;
              background-color: $colorMiddle;
              border-radius: 100px;
              width: 40px;
              display: block;
              text-align: center;
              padding-left: 1.5px;
              padding-right: 0.5px;
              padding-top: 0.5px;
            }
          }
        }

        &.bad {
          color: $colorBad;

          &:before {
            content: "\f119";
            font-family: FontAwesome;
            font-style: normal;
            font-weight: normal;
            text-decoration: inherit;
          }

          &:hover, &:checked {
            &:before {
              color: white;
              background-color: $colorBad;
              border-radius: 100px;
              width: 40px;
              display: block;
              text-align: center;
              padding-left: 1.5px;
              padding-right: 0.5px;
              padding-top: 0.5px;
            }
          }
        }
      }
    }
  }

  .bloc-tarif {
    margin-top: 10px;
  }
}