.flash-messages {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 999999;

  .container-flash-messages {
    display: block;
    width: 100%;
    margin: 0;
    position: relative;
    padding: 0;
    font-size: 21px;
    font-weight: 200;

    .alert {
      margin: 2px;
      display: block;
      width: 100%;
      margin: 0;
      position: relative;
      border: 0px;
      border-radius: 0px;
      box-shadow: none;
      -moz-box-shadow: none;
      -webkit-box-shadow: none;
    }

  }
}
