
$float-labels: (
        color-placeholder : #878787
);

@import "../../../../../node_modules/float-labels.js/src/float-labels";

.fl-form {
  input {
    &.fl-input {
      border-radius: 0 !important;
    }
  }

  select {
    &.fl-select {
      border-radius: 0 !important;
    }
  }

  textarea {
    &.fl-textarea {
      border-radius: 0 !important;
    }
  }
}