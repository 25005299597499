.select2-selection {
  color: #444;
  border-radius: 0 !important;
  position: relative;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 100%;
  outline: 0;
  font-size: 16px;
  line-height: 1.5;
  border: 1px solid #dfdfdf !important;
  background-color: #fff;
  box-sizing: border-box;
  transition: all .2s ease-in-out;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);

  &__required {
    border-color: $colorRequired !important;
  }
}

.select2-selection--single {
  height: 34px;
}

.select2-selection__choice {
  color: #888 !important;
}