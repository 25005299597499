$breakpoints: (
        'tablet': (min-width: 768px),
        'desktop': (min-width: 992px),
        'large': (min-width: 1200px),
        'extra-large': (min-width: 1500px),
) !global;


$mainLogoDarkColor: #3A4C5A;

$mainLogoGoldColor: #E2C961;

$mainColorBlue: rgb(95, 130, 140);

$mainColorDark: rgb(77, 99, 115);

$colorGood: #3CEF42;
$colorMiddle: #FE9901;
$colorBad: #FE0101;

$colorBoxHeaderBasic: #91a0ae;
$colorBoxHeaderImportant: #445868;
$colorBoxHeaderRed: #ae9791;
$colorBoxHeaderBlocNote: #c6a080;
$colorBoxHeaderLight: #adae91;
$colorIconEdit: #73c06b;
$colorIconRemove: #9f0c00;
$colorLegends: #b5b5b5;
$colorBorder: #e5e5e5;
$colorBgText: #E8EEF3;
$colorBorderInput: #dfdfdf;
$colorBtnSuccess: #5cb85c;
$colorBtnDanger: #d9534f;
$colorRequired: #ababab;

$colorControleTitle: #3869a1;
$colorMainBlocHeader: #445868;
$colorControlePart: (
    1:$colorMainBlocHeader,
    2:#3869a1,
    3:#4682c7,
    4:#5d8fc7,
    5:#83a3c7,
    6:#a5b5c7
);
$colorBgSeparator: #717171;

$colorVaccinationTitle: #448157;
$colorVaccinationPicto: #75ae55;
$colorVaccinationPart: (
    1:$colorMainBlocHeader,
    2:$colorVaccinationTitle,
    3:#5d9756,
    4:$colorVaccinationPicto,
    5:#8ec453,
    6:#a6da52,
    7:#b5dd74,
    8:#bfdc91,
);

$colorEvaluationTitle: #1b5d69;
$colorEvaluationPart: (
    1:$colorMainBlocHeader,
    2:$colorEvaluationTitle,
    3:#207381,
    4:#288d9f,
    5:#02abae,
    6:#9bd1ba,
);

$colorExpertiseTitle: #6d4f73;

$colorLTE: (
    required: #D32F2F,
    placeholder: #bbb,
    label-text: #444,
);

$colorFinancialHealthRj: #ffa500;
$colorFinancialHealthOther: #ff0000;

$colorComptableSelectionImportant: #dad9c5;
$colorComptableSelectionNormal: #edeee8;
$colorComptableBtnDownloadSelection: #faab4c;
$colorComptableBtnDownloadOne: #f8a032;

$colorHeaderUser: #ffad08;
$borderTab: #f4f4f4;
$colorSuspensifs: #a72b2b;
$colorJustifies: #1db2b7;

$colorBtnActe: #955BA5;

$colorFormEmployee: #f6f6f6;

$colorGreenAlgoDoctor: #1a8a1a;

$colorTextAreaBlocNote: #f7f7f7;
