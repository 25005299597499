body {
    &.comptable-bill-page {
        .generation-link:hover {
            background-color: rgba(100, 100, 100, 0.2);
            cursor: pointer;
        }
        .content {
            margin-top: 20px;

            .container-bill {
                &__result {
                    padding: 30px;
                    background-color: #fff;

                    &__element {
                        margin-top: 20px;

                        .element {
                            &__bloc-download {
                                margin-top: 20px;
                            }
                        }

                        &__table-bill {
                            margin-bottom: 0;

                            .icheckbox_square-green {
                                background-color: #fefefe;
                            }

                            &.list {
                                tbody {
                                    tr {
                                        .last-td {
                                            background-color: $colorComptableSelectionNormal !important;
                                        }
                                        &:nth-child(2n+1) {
                                            .last-td {
                                                background-color: $colorComptableSelectionImportant !important;
                                            }
                                        }
                                    }
                                }
                            }

                            thead {
                                .table-bill {
                                    &__th-selection {
                                        background-color: $colorComptableSelectionImportant;
                                    }
                                }
                            }

                            tbody {
                                tr {
                                    td {
                                        cursor: pointer;

                                        &:last-child {
                                            cursor: auto;
                                        }

                                        .indication {
                                            font-size: 10px;
                                        }

                                        &.table-bill {
                                            &__td-selection {
                                                background-color: $colorComptableSelectionNormal;
                                            }
                                        }
                                    }
                                }

                                .btn-bills-download {
                                    background-color: #FFFFFF;
                                    color: $colorComptableBtnDownloadOne;
                                    border: 1px solid $colorComptableBtnDownloadOne;
                                    font-weight: bold;
                                }
                            }
                        }

                        &__details {
                            margin-left: 20px;
                            padding-top: 10px;

                            .list {
                                padding-left: 0;

                                &__title {
                                    font-size: 1.2em;
                                }
                            }
                        }
                    }

                    &__btn-generate {
                        padding: 17px;
                        font-weight: bold;
                    }
                }
            }
            .bloc-bill-search {
                .box-body {
                    padding: 30px;
                }
                .form-search {
                    .datepicker {
                        margin-bottom: 0;
                    }
                    &__submit-button {
                        margin-top: 10px;
                    }
                }
            }
        }

        .page-title {
            &__picto {
                &__big {
                    font-size: 35px;
                }

                &__small {
                    font-size: 20px;
                }
            }
        }

        .btn-bdc-submit {
            margin-right: 220px;
            margin-top: 3px;
        }

    }

    #datatable-customer, #datatable-doctor {
        .red-amount {
            font-weight: bold;
            color: #FE0101;
        }

        .green-amount {
            font-weight: bold;
            color: #00dc07;
        }

        .show-row-details {
            cursor: pointer;
        }

        .details-row {
            hr {
                margin: 7px 0;
            }
        }

        .btn-save-payment-date, .btn-save-payment-tracking {
            cursor: pointer;
            margin-left: 5px;
        }

        .save-success {
            margin-left: 5px;
            color: #3c763d;
        }

        .payment-date, .payment-date-picker {
            width: 100%;
        }

        .payment-date * {
            padding: 0;
        }

        td {
            vertical-align: middle;
        }

        .payment_tracking_field {
            resize: none;
            max-width: 80%;
        }

        .btn-center {
            margin: auto;
        }
    }
}
