html{
    width: 100%;
    height: 100%;

    body {
        -webkit-print-color-adjust: exact;

        &.pdf {
            table {
                tr {
                    page-break-inside: avoid;
                }
            }

            .content-specific {
                font-size: 12px;
                text-align: justify;
            }

            .container {
                padding-bottom: 25px;

                .content-pdf {
                    .lieu-date {
                        margin-top: 90px;
                    }

                    .element {
                        margin-top: 17px;

                        .infos-bancaires {
                            margin-top: 10px;
                            margin-bottom: 10px;

                            .coordonnees-bancaires {
                                color: #FF0000;
                                font-weight: bold;
                            }
                        }

                        .bloc {
                            border: 1px solid $mainLogoGoldColor;
                            padding: 10px;
                        }

                        .table-bill {
                            thead {
                                display: table-row-group;

                                th {
                                    &.th-designation {
                                        width: 50%;
                                    }
                                }
                            }

                            td {
                                border: none !important;

                                &.td-first-medical-control {
                                    padding-left: 25px;
                                }

                                &.td-first-billing-line {
                                    padding-left: 50px;
                                }
                            }
                        }
                    }

                    .document-odm-evaluation {
                        font-size: 20px;

                        .title-document {
                            font-size: 30px;
                            text-decoration: underline;
                            color: $mainLogoGoldColor;
                            text-underline: $mainLogoGoldColor;
                            font-weight: bold;
                        }

                        .text-decale {
                            margin-left: 20px;
                        }

                        .bloc-date-rdv {
                            margin-top: 40px;
                            margin-bottom: 40px;

                            .date-rdv {
                                font-size: 30px;
                                color: red;
                            }
                        }

                        .legende-date-rdv {
                            font-size: 20px;
                        }

                        hr {
                            border-color: black;
                            margin-top: 30px;

                            &.hr-blue {
                                border-color: blue;

                                &:nth-of-type(2) {
                                    margin-bottom: 2px;
                                }

                                &:nth-of-type(3) {
                                    margin-top: 3px;
                                }
                            }
                        }

                        .big-title {
                            font-size: 25px;
                            margin-top: 37px;
                            text-decoration: underline;
                        }

                        .cachet {
                            border: 1px solid black;
                            width: 100%;
                            height: 120px;
                        }
                    }
                }
            }
        }
    }
}