@import 'utils/vars';
@import 'utils/mixins';
@import 'components/flash';
@import 'components/reset';
@import 'components/main';
@import 'components/fonts';
@import 'components/sprite';
@include sprites($spritesheet-sprites);

@import 'vendors/admin-lte'; // Override AdminLTE
@import 'vendors/orgchart'; // Override Orgchart
@import 'vendors/icheck'; // Override iCheck
@import 'vendors/datatable'; // Override DataTables
@import 'vendors/bootstrap'; // Override Bootstrap
@import 'vendors/float-labels'; // Override Float-labels.js
@import 'vendors/select2'; // Override select2
@import 'vendors/introjs'; // Override introJS
@import 'vendors/datepicker'; // Override datepicker

@import 'components/satisfaction'; // Smileys satisfaction and comment form
@import 'components/btn'; // Buttons
@import 'components/calendar'; // Calendar
@import 'pages/customer'; // Style for customer pages
@import 'pages/user'; // Style for user pages
@import 'pages/doctor'; // Style for doctor pages
@import 'pages/medicalcontrol'; // Style for medicalcontrol pages
@import 'pages/home'; // Style for the home page (dashboard)
@import 'pages/comptable'; // Style for comptable pages
@import 'pages/_pdf'; // Style for PDF pages