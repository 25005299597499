/**
 * Medical Control
 */

body {
    //Page de controle
    &.controle-page {
        @import "common_doctor_control";
        .steps{
            &__item{
                border-radius: 0;
                margin-bottom: 2px;
                position: relative;
                .box-header{
                    color: white;
                    position: relative;
                    padding: 0;
                    border-radius: 0;
                    &:after{
                        content:"";
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        width: 100%;
                        height: 3px;
                        background: rgba(0,0,0,.2);
                    }
                    &__picto{
                        float: right;
                        margin-top: 3px;
                    }
                    &__icon{
                        float: left;
                        font-size: 20px;
                        margin-right: 10px;
                    }
                    &__metadata{
                      font-size: 10px;
                      font-style: italic;
                    }
                    .pointer{
                        display: block;
                        padding: 10px;
                        &[aria-expanded="true"]{
                            .box-header__picto{
                                &:before{
                                    @extend %picto-minus;
                                }
                            }
                        }
                    }
                }
                .box-body{
                    position: relative;
                    &__tools{
                        position: absolute;
                        z-index: 1;
                        right: 20px;
                        top:20px;
                        .icon-action{
                            padding: 7px;
                        }
                        &_planning{
                            position: absolute;
                            z-index: 1;
                            right: 20px;
                            top:-4px;
                            .icon-action{
                                padding: 7px;
                            }
                        }
                    }
                    &__row{
                        margin-bottom: 30px;
                    }
                }
                &.bloc-doctor {
                    .form-step-doctor {
                        .element-form-customer {
                            margin-top: 5px;
                        }
                    }
                }
                &.bloc-control-organisation{
                    .box-body{
                        &__edit{
                            .icon-action{
                                padding: 7px;
                            }
                        }
                    }
                    .corga-elements {
                        &__element {
                            .picto {
                                cursor: pointer;
                                &:hover {
                                    opacity: 0.5;
                                }
                            }
                        }
                    }
                }
                &.bloc-infos-employeur{
                    .box-header{
                        background: $colorMainBlocHeader;
                    }
                    .box-body{
                        &__row{
                            margin-bottom: 10px;
                            display: inline-flex;
                            align-items: center;
                            width: 100%;
                        }
                    }
                }
                &.bloc-preferences-doctor{
                    .box-body{
                        padding: 0;
                    }
                    .panel-collapse{
                        margin-top: -3px;
                    }
                    .nav-preferences {
                        &__item{
                            &:first-child{
                                display: none;
                            }
                        }
                    }
                }
                .input-bloc-note{
                    background-color: $colorTextAreaBlocNote;
                }
                .btn-save-note {
                    margin-right: 10px;
                    border-radius: 5px;
                }
            }
            .icon-action{
                padding: 7px;
            }
            .infos-cg{
                padding-left: 0;
                margin-top: 30px;
                .infos-supp-rdv {
                    margin-left: 30px;
                }
            }
            .buttons-medical-control-actions{
                margin: 0;
            }
        }
        .main-box{
            dt, dd{
                margin-left: 0;
                margin-right: 0;
                float: left;
                width: 50%;
                padding-right: 15px;
            }
        }
        .bloc{
            &-employee {
                &__row{
                    margin-bottom: 15px;
                }
                &__title {
                    text-align: left;
                    &_arret{
                        width: 50%;
                        margin-top: 7px;
                    }
                    &.icon {
                        width: 70px;
                        font-size: 25px;
                    }
                }
                &__text {
                    width: auto;
                    overflow-wrap: break-word;
                    &_arret{
                        margin-top: 7px;
                    }
                }
                &__arret{
                    background-color: #E89176;
                    margin-top: 25px;
                    margin-bottom: 25px;
                    font-weight: bold;
                    padding: 20px;
                }
                &__rechute{
                    display: inline-flex;
                    &__text{
                        color: $colorLegends;
                        font-size: 0.9em;
                        display: inline-flex;
                        align-items: center;
                        margin-left:5px;
                        [class*=" picto-"]{
                            margin-right: 5px;
                        }
                    }
                }
                &__other-address {
                    font-size: 16px;
                    text-decoration: underline;
                }
            }
            &-medical-control-second-right {
                margin-top: 30px;
            }
            &-preferences-employeur{
                margin-top: 30px;

                &__row{
                    margin-bottom: 7px;
                    &:last-child{
                        margin-bottom: 0;
                    }

                    &__health {
                        font-weight: bold;
                        color: $colorFinancialHealthOther;
                    }
                }
            }
            &-final-need {
                .final-need-document {
                    .delete-link {
                        margin-left: 5px;

                        .icon-action {
                            font-size: 12px;
                            padding: 5px 7px 5px 7px;
                        }
                    }
                }
            }
            &-bilan {
                .bloc-details-bilan {
                  margin: 0;
                }
                .infos-contact {
                    margin-top: 20px;
                    padding: 16px;
                    background: #e8eef359;

                    &.others-mails {
                        background: white;
                        border: 3px solid #e8eef359;
                    }

                    .corga-elements {
                        &__element {
                            margin-top: 10px;
                            a {
                                &:hover {
                                    text-decoration: none;
                                }
                                .picto {
                                    cursor: pointer;
                                    font-size: 30px;
                                    color: #4d6373;
                                    margin: 0 10px;
                                    &:hover {
                                        opacity: 0.5;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            &-base-control {
                .box-body{
                    padding: 20px 30px;
                    background-color: $mainLogoGoldColor;
                    min-height: 50px;
                    &__title {
                        color: #5E7F7B;
                        text-align: left;
                    }
                }
            }
            &-transmission {
                &__row{
                    &:first-child{
                        margin-bottom:15px;
                    }
                }
                &__picto {
                    font-size: 1.8em;
                    margin: 0 5px;
                    &:nth-child(even){
                        color: $colorVaccinationTitle;
                    }
                }
            }
            &-control-organisation{
                .picto {
                    font-size: 2em;
                    margin: 0 10px;
                    color: #4d6373;
                    &:first-of-type{
                        margin-left: 5px;
                    }
                }
            }
            &-bill {
              .table-billing {
                &__honoraires {
                  background-color: #00ff1f14 !important;
                }
              }
            }
            &-preferences-doctor{
                &__no-result{
                    padding: 15px;
                }
            }
            &-details-bilan {
                .medical-control-important-text {
                    max-width: calc(100% - 40px);
                }
            }
            &-form-co-type {
                .radio {
                    margin: 0;
                    float: left;
                    width: 33%;
                }
            }
        }
        .picto-list{
            margin-right: 10px;
            &:nth-child(even){
                color: $colorVaccinationPicto;
            }
        }
        .infos-cg {
            padding-left: 25px;
        }
        .medical-control-important-text {
            padding: 5px;
            background-color: $colorBgText;

            &.type-cg {
                font-size: 17px;
                display: inline-block;
            }
        }
        .comments-medical-control {
            padding: 5px;
            width: 100%;
            min-height: 80px;
            background-color: #F6F7FB;
        }
        .alert-medical-control {
            margin-top: 20px;
            margin-bottom: 50px;
            font-size: 20px;
        }
        .line-separator {
            height:1px;
            background: $colorBgSeparator;
            border-bottom:1px solid $mainLogoDarkColor;
        }
        dt, dd{
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        &.controle{
            .page-title{
                background: $colorControleTitle;
                &__text{
                    background: $colorControleTitle;
                }
                &__picto{
                    background: $colorControleTitle;
                }
            }
            .steps{
                &>div{
                    @each $item, $bgcolor in $colorControlePart {
                        &:nth-child(#{$item}){
                            .box-header {
                                background: $bgcolor;
                            }
                        }
                    }
                }
            }
            .form-primary{
                .sending-choice{
                    &:hover, &:checked {
                        color: $colorControleTitle;
                        text-shadow: 0px 0px 0px $colorControleTitle;
                    }
                }
            }
            .table-billing {
              &__facturation {
                @include colorHonoraires(controle);
              }
            }
        }
        &.vaccination{
            .page-title{
                background: $colorVaccinationTitle;
                &__text{
                    background: $colorVaccinationTitle;
                }
                &__picto{
                    background: $colorVaccinationTitle;
                }
            }
            .steps{
                &>div{
                    @each $item, $bgcolor in $colorVaccinationPart {
                        &:nth-child(#{$item}){
                            .box-header {
                                background: $bgcolor;
                            }
                        }
                    }
                }
            }
            .table-billing {
                &__facturation {
                    @include colorHonoraires(vaccination);
                }
            }
            .vaccination{
                &__estimate{
                    &__row{
                        margin-bottom: 10px;
                    }
                    dd.pull-left{
                        padding-left: 5px;
                    }
                    &__icon {
                        font-size:2em;
                        padding-right: 0;
                    }
                }
                &__row{
                    margin-bottom: 10px;
                }
                &__document {
                  white-space: normal;
                }
            }
            .bloc{
                &-rdv {
                    &__descriptions {
                        line-height:normal;
                        [class*="picto-"] {
                            &:not(.picto-small){
                                font-size: 24px;
                            }
                        }
                    }
                    &__date{
                        padding: 0 15px;
                    }
                    &__picto{
                        color: $colorVaccinationPicto;
                    }
                }
                &-final-need{
                    &__nb-vaccins{
                        margin-left: 10px;
                        line-height: 30px;
                        height: 30px;
                        width: 30px;
                        text-align: center;
                        background: $colorBgText;
                    }
                    &__supply{
                        [class*="picto-"] {
                            margin-left: 15px;
                            margin-right: 5px;
                        }
                    }
                    &__descriptions{
                        [class*="picto-"] {
                            &:not(.picto-small){
                                font-size: 24px;
                            }
                        }
                    }
                }
            }

            .form-primary{
                .sending-choice{
                    &:hover, &:checked {
                        color: $colorVaccinationTitle;
                        text-shadow: 0px 0px 0px $colorVaccinationTitle;
                    }
                }
            }
        }
        &.expertise{
            .page-title{
                background: $colorExpertiseTitle;
                &__text{
                    background: $colorExpertiseTitle;
                }
                &__picto{
                    background: $colorExpertiseTitle;
                }
            }
            .steps{
                &>div{
                    @include gradient-bg($colorExpertiseTitle, 6);
                }
            }
            .form-primary{
                .sending-choice{
                    &:hover, &:checked {
                        color: $colorExpertiseTitle;
                        text-shadow: 0px 0px 0px $colorExpertiseTitle;
                    }
                }
            }
            .table-billing {
                &__facturation {
                    @include colorHonoraires();
                }
            }
        }
        &.evaluation{
            .page-title{
                background: $colorEvaluationTitle;
                &__text{
                    background: $colorEvaluationTitle;
                }
                &__picto{
                    background: $colorEvaluationTitle;
                }
            }
            .steps{
                &>div{
                    @each $item, $bgcolor in $colorEvaluationPart {
                        &:nth-child(#{$item}){
                            .box-header {
                                background: $bgcolor;
                            }
                        }
                    }
                }
            }
            .form-primary{
                .sending-choice{
                    &:hover, &:checked {
                        color: $colorEvaluationTitle;
                        text-shadow: 0px 0px 0px $colorEvaluationTitle;
                    }
                }
            }
            .table-billing {
                &__facturation {
                    @include colorHonoraires(evaluation);
                }
            }
        }

        //Mise en page formulaire
        .form-primary {
            .input-sending-choice {
                .checkbox {
                    display: inline-block;
                    margin-left: 10px;
                    width: 40px;
                    padding-top: 0;
                    label {
                        padding-left: 0;
                    }
                    input[type="checkbox"] {
                        -webkit-appearance: none;
                        -moz-appearance: none;
                        width: auto;
                        height: auto;
                        border: none;
                        cursor: pointer;
                        margin: 0 5px;
                        font-size: 30px;
                        &:focus {
                            outline: 0;
                        }

                        &.sending-choice {
                            &.message {
                                &:before {
                                    @include font-awesome('\f075');
                                }
                            }
                            &.tel {
                                &:before {
                                    @include font-awesome('\f095');
                                }
                            }
                            &.email {
                                &:before {
                                    @include font-awesome('\f003');
                                }
                            }
                            &.fax {
                                &:before {
                                    @include font-awesome('\f1ac');
                                }
                            }
                            &.mail {
                                &:before {
                                    @include font-awesome('\f02f');
                                }
                            }
                            &:before {
                                text-decoration: inherit;
                            }
                        }
                    }
                }
            }

            .rdv_salarie, .odm, .client{
                .element-form-customer {
                    display: inline-block;
                }
            }

            &.form-final-need{
                .fl-is-required{
                    &:before{
                        right: 5px;
                    }
                }
            }

            &.form-control-organisation {
                .bloc-rdv-control-organisation {
                    margin-top: 30px;
                    &:nth-child(2n) {
                        background-color: #e0e0e0;
                    }
                    &:nth-child(2n+1) {
                        background-color: #efefef;
                    }

                    .container-change-address-doctor {
                        display: none;
                    }
                }

                .btn-prototype {
                    margin-top: 20px;
                    margin-bottom: 10px;
                }
            }
        }

        //Popup ajout besoins
        .form-estimate-need {
            .form-dispo-dates {
                margin-top: 10px;
                margin-bottom: 30px;
                padding: 0;
                .confirm{
                    margin-top: 10px;
                    text-align: center;
                }
            }

            .fl-is-required{
                &:before{
                    right: 5px;
                }
            }
        }

        //Popup modifier bilan
        .form-bilan {
            &__contacts {
                margin-top: 10px;
                margin-bottom: 30px;
                padding: 0;
            }
            .control-label {
                margin-bottom: 5px;
            }
            .select2-selection{
                &__rendered{
                    margin-top: 0;
                }
            }
            .confirm {
                margin-top: 10px;
            }
            .bilan-cpam-mail-crypte {
                color: green;
            }
            .bilan-cpam-mail-non-crypte {
                color: red;
            }
        }

        .select2-selection {
            &__rendered {
                max-width: 100%;
            }
            &__choice {
                max-width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        .field {
            &-place {
                border-top: 1px solid $colorBorder;
                margin-top: 15px;
                .element-form-customer {
                    margin-top: 15px;
                }
            }
        }

        #leaflet-canvas{
            min-height: 500px;
            margin-bottom: 100px;
        }

        .km {
            &-green{
                color: $colorGreenAlgoDoctor
            }
            &-orange{
                color: $colorMiddle
            }
            &-red{
                color: $colorBtnDanger
            }
        }

        .map-datatable {
            margin-top: 20px;
        }

        .row-btn-doctor {
            margin-top: 10px;
        }

        .link-new-tab {
            text-decoration: none;
            color: white;
            &:hover {
                text-decoration: none;
                color: white;
            }
        }
    }

    //Page de listing
    &.control-list-page {
        .tab-export {
            &__type {
                margin-bottom: 20px;
                &__item {
                    padding: 0 15px;
                }

                label.fl-label {
                    position: static;
                }
                .select2-container {
                    min-width: 400px;
                    max-width: 400px;
                    max-height: 40px;
                    overflow: auto;
                }

            }
        }
        table.dataTable {
            border-collapse: collapse;
        }
        .avct {
            &__J5 {
                background-color: $colorBtnDanger;
            }
            &__J4 {
                background-color: lighten($colorBtnDanger, 10);
            }
            &__J3 {
                background-color: darken($colorHeaderUser, 10);
            }
            &__J2 {
                background-color: $colorHeaderUser;
            }
            &__J1 {
                background-color: darken($colorBtnSuccess, 5);
            }
            &__J0 {
                background-color: $colorBtnSuccess;
            }
        }
        .ctrl-row {
            color: black;
            &:first-child {
                .ctrl-row__td {
                    border: 0;
                }
            }
            &.ctrl {
                border-width: 2px;
                border-right: 0px;
                border-left: 0px;
                border-top: 0px;
                border-spacing: 5px;
                border-style: solid;
                border-collapse: collapse !important;
                &-J6 {
                    @extend .ctrl;
                    border-bottom-color: $colorBtnDanger;
                    background-color: rgba(217, 83, 79, 0.2);
                }
                &-J5 {
                    @extend .ctrl;
                    border-bottom-color: $colorBtnDanger;
                }
                &-J4 {
                    @extend .ctrl;
                    border-bottom-color: lighten($colorBtnDanger, 10);
                }
                &-J3 {
                    @extend .ctrl;
                    border-bottom-color: darken($colorHeaderUser, 10);
                }
                &-J2 {
                    @extend .ctrl;
                    border-bottom-color: $colorHeaderUser;
                }
                &-J1 {
                    @extend .ctrl;
                    border-bottom-color: darken($colorBtnSuccess, 5);
                }
                &-J0 {
                    @extend .ctrl;
                    border-bottom-color: $colorBtnSuccess;
                }
            }
        }

        .box-header {
            .pointer {
                display: block;
                &[aria-expanded="true"] {
                    .box-header__picto {
                        &:before {
                            @extend %picto-minus;
                        }
                    }
                }
            }
        }
    }

    .form-work-stoppage {
        .form-employee {
            &__btn-edit {
                i {
                    margin-top: 30px;
                }
            }
            &__existing-patient{
                background-color: $colorFormEmployee;
            }
        }

        .bloc-prototype-collection {
            .btn-danger {
                margin-top: 29px;
            }
        }

        .table-ref-complementaire {
            margin-top: 15px;
        }
    }

}

@include respond-to(extra-large){
    body {
        &.controle-page {
            .bloc-employee {
                &__arret {
                    padding: 20px 70px;
                    padding-bottom: 5px;
                }
                &__title {
                    &.icon {
                        width: 70px;
                    }
                }
                &__title__underlined {
                    text-decoration: underline;
                }
            }
        }
    }
}
@include respond-to(large){
    body {
        &.controle-page {
            .bloc-employee {
                &__title {
                    &.icon {
                        width: 70px;
                    }
                }
            }
        }
    }
}


