.panel {
  &.box-success {
    border-top-width: 3px;

    .box-header {
      .box-title {
        a {
          color: $mainColorDark;
          text-decoration: none;

          &:hover {
            text-decoration: none;
          }
        }
      }
    }
  }
}

form {
  .form-label {
    border-radius: 0 !important;
  }
}

.modal {
  .modal-content {
    border-radius: 0;

    .modal-body {
      .content {
        min-height: initial;
      }
    }
  }
}

.nav-tabs-custom {
  position: relative;

  .overlay, .overlay-wrapper .overlay  {
    z-index: 50 !important;
    background: rgba(255, 255, 255, 0.7) !important;
    border-radius: 0px !important;

    &>.fa {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -15px;
      margin-top: -15px;
      color: #000;
      font-size: 30px;
    }
  }

  &>.overlay, .overlay-wrapper>.overlay, .box>.loading-img, .overlay-wrapper>.loading-img {
    position: absolute;
    top: 0;
    left: 0px;
    width: 100%;
    height: 100%;
  }
}

.btn {
  border-radius: 0;
}

.overlay {
  .fa-spin {
    font-size: 23px !important;
    top: 61% !important;
  }
}