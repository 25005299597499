.doctor-expertises {
    &__table {
        border-collapse: separate;
        border-spacing: 5px;
        width: 80%;
        margin-left: 10%;
        &__line {
            vertical-align: top;
        }
    }
    &__title {
        text-align: center;
        font-size: 16px;
        margin-top: 10px;
        text-decoration: underline;
    }
}

.bloc-preferences {
    .nav-preferences {
        background-color: $colorBoxHeaderBasic;
        color: #fff;
        &__item {
            .link {
                &:not([aria-expanded="true"]) {
                    color: #fff;
                    &:hover {
                        color: #fff;
                    }
                }
            }
        }
    }
    &__title {
        margin-top: 15px;
        text-align: center;
        font-size: 16px;
        text-decoration: underline;
    }
    &__item {
        margin-top: 20px;
        &__title {
            margin-top: 15px;
            display: inline;

            .title {
                text-decoration: underline;
            }
        }
    }
    &__communication-mode {
        width: 100%;
        border-collapse: separate;
        border-spacing: 5px;
    }
    .tab-content {
        min-height: 210px;
    }
}

.doctor-show-box-secondary {
    background-color: #FFFFFF;
    .info-assujeti-tva {
        margin-top: 25px;
        margin-left: 14px;
    }
    .bottom-block {
        margin-top: 20px;
    }
}

.doctor-separator {
    margin-top: 10px;
}

.doctor-full-table {
    width: 100%;
}

.doctor-comment-icon {
    float: left;
    margin-right: 20px;
    margin-top: 5px;
    display: inline;
}

.doctor-chart-donut {
    width: 400px;
    height: 200px;
    margin: auto;
}