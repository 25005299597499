@mixin font-awesome($content) {
	font-family: FontAwesome;
	font-style: normal;
	font-weight: normal;
	
	@if ($content != '') {
		content: $content;
	}
}

@mixin respond-to($breakpoint) {
	// If the key exists in the map
	@if map-has-key($breakpoints, $breakpoint) {
		// Prints a media query based on the value
		@media #{inspect(map-get($breakpoints, $breakpoint))} {
			@content;
		}
	}
		
		// If the key doesn't exist in the map
	@else {
		@error 'No value found for `#{$breakpoint}`. '
         + 'Please make sure it is defined in `$breakpoints` map.';
	}
}

@mixin gradient-bg($mainColor: $colorMainBlocHeader, $items: 6) {
	&:nth-child(1){
		.box-header {
			background: $colorMainBlocHeader;
		}
	}
	&:nth-child(2){
		.box-header {
			background: $mainColor;
		}
	}

	$ligthGradient : 0;
	@for $i from 3 through $items {
		$ligthGradient: $ligthGradient + 10;
		&:nth-child(#{$i}) {
			.box-header {
				background: lighten($mainColor, $ligthGradient * 1%);
			}
		}
	}
}

@mixin hex-to-rgba($bgColor: #ffffff, $opacity: .6) {
    background: rgba(red($bgColor), green($bgColor), blue($bgColor), $opacity);
}

@mixin colorHonoraires($page: '') {
	$color: "";
	@if $page == 'controle' {
		$color: lighten($colorControleTitle, 50%);
	}
	@else if($page == 'vaccination' ) {
		$color: lighten($colorVaccinationTitle, 50%);
	}
	@else if($page == 'evaluation' ) {
		$color: lighten($colorEvaluationTitle, 50%);
	}
	@else {
		$color: lighten($colorExpertiseTitle, 50%);
	}

	background-color: $color;
}
