.user-form {
  margin-top: 50px;

  .label-user-roles {
    padding-left: 0;
  }

  .roles, .user-form-signature {
    .has-error {
      .error-form-mp {
        margin-top: 0;
      }
    }
  }
}