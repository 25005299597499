body {
    font-family: Comfortaa, serif;

    textarea {
        resize: vertical;
    }

    label {
        margin-bottom: 0;
    }

    fieldset {
        display: block;
        margin-left: 2px;
        margin-right: 2px;
        padding: 0.35em 0.75em 25px;
        border: 1px groove #eaeaea63;
        margin-top: 20px;

        legend {
            border-bottom: none;
            padding-left: 10px;
            font-size: 15px;
            margin-bottom: -20px;
        }
    }

    .row-fluid{
        margin: 0;
    }

    .row-eq-height{
        display: flex;
        align-items: center;
    }

    .col-fluid {
        padding: 0;
    }

    .col-flex{
        display: flex;
        line-height: normal;
    }

    [class*="sprite-"]{
        display: inline-block;
    }

    .display-inline-block {
        display: inline-block;
    }

    @include respond-to(extra-large){
        .col-xl-2{
            width: 16.66666667%;
        }
        .col-xl-3{
            width: 25%;
        }
        .col-xl-4{
            width: 33.33333333%;
        }
        .col-xl-5{
            width: 41.66666667%;
        }
        .col-xl-6{
            width: 50%;
        }
        .col-xl-7{
            width: 58.33333333%;
        }
        .col-xl-8{
            width: 66.66666667%;
        }
        .col-xl-10{
            width: 83.33333333%;
        }
        .col-xl-offset-2 {
            margin-left: 16.66666667%;
        }
    }
}
