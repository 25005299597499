.btn-mp-gold {

  &__disabled{
    background-color: $colorLegends;
    color: #ffffff;
    text-align: center;

    &:hover {
      background-color: lighten($mainLogoGoldColor, 10);
    }
  }
  background-color: $mainLogoGoldColor;
  color: #ffffff;
  text-align: center;

  &:hover {
    background-color: lighten($mainLogoGoldColor, 10);
  }
}


.btn {
  &.small-icon {
    padding: 1px 5px;
    font-size: 10px;
    cursor: initial;
    
    &.btn-success {
      &:hover, &:active, &:focus {
        color: #fff;
        background-color: #5cb85c;
        border-color: #4cae4c;
        box-shadow: none;
      }
    }
    
    &.btn-danger {
      &:hover, &:active, &:focus {
        color: #fff;
        background-color: $colorBtnDanger;
        border-color: #d43f3a;
        box-shadow: none;
      }
    }
  }
}
