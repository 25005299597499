.home-page {
  .box-header {
    &__picto {
      float: right;
    }
  }

  .home {
    &__stat-knob {
      margin: auto;
      width: 100px;
      height: 100px;
    }

    &__label-knob {
      text-align: center;
    }

    &__picto {
      &__bad {
        color: $colorBtnDanger;
      }

      &__good {
        color: $colorBtnSuccess;
      }
    }

    &__stat {
      &__doctor {
        color: $colorBtnActe;
      }

      &__customer {
        color: $colorBtnSuccess;
      }

      &__cancel {
        color: $colorBtnDanger;
      }

      &__stat-item {
        text-align: right;

        &__important {
          text-align: right;
          font-weight: 800;
        }
      }

      &__important {
        font-weight: 800;
      }
    }

    &__btn-filter {
      margin-top: 15px;
      height: 40px;
    }

  }

  .pointer {
    &[aria-expanded = "true"] {
      .box-header {
        &__picto {
          &:before {
            @extend %picto-minus;
          }
        }
      }
    }
  }

  .database-link {
    &__item {
      color: $colorBgText;
      padding: 15px 20px;
      overflow: hidden;
      position: relative;

      &:before {
        content: "";
        position: absolute;
        left: 20%;
        top: -57%;
        width: 250px;
        height: 250px;
        background: rgba(255, 255, 255, 0.2);
        transform: rotate(-55deg);
        opacity: 0;
        transition: opacity .2s;
      }

      &:after {
        content: "";
        position: absolute;
        left: 67%;
        top: -57%;
        width: 250px;
        height: 250px;
        background: rgba(255, 255, 255, 0.2);
        transform: rotate(-55deg);
        opacity: 0;
        transition: opacity 1s;
      }

      &:hover {
        text-decoration: none;

        &:after, &:before {
          opacity: 1;
        }
      }

      &.item {
        &-customer {
          background-color: $colorVaccinationTitle;
          border-color: darken($colorVaccinationTitle, 5);
        }

        &-doctor {
          background-color: $colorControleTitle;
          border-color: darken($colorControleTitle, 5);
        }

        &-cpam {
          background-color: $colorBtnDanger;
          border-color: darken($colorBtnDanger, 5);
        }

        &-acte {
          background-color: $colorBtnActe;
          border-color: darken($colorBtnActe, 5);
        }
      }
    }
  }

  .info-act {
    margin-bottom: 30px;

    &-right-item {
      padding-left: 0;

      p {
        margin: 5px 0;
      }
    }

    &-left-item {
      text-align: center;
      color: $mainColorDark;
      border-radius: 50px;
      height: 80px;
      width: 80px;
      position: relative;
      margin: 0;

      &__ctrl-expertise {
        background-color: lighten($colorExpertiseTitle, 30);
      }

      &__ctrl-at {
        background-color: map-get($colorControlePart, 5);
      }

      &__ctrl-eval {
        background-color: map-get($colorEvaluationPart, 6);
      }

      &__ctrl-vaccination {
        background-color: map-get($colorVaccinationPart, 7);
      }

      &__ctrl {
        background-color: map-get($colorControlePart, 6);
      }

      &__text {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 1px);
      }
    }
  }

  .legend {
    &__label {
      margin-bottom: 20px;
    }

    &__number {
      text-align: right;
    }
  }

  .box-donut {
    padding-top: 20px;
    cursor: pointer;
    border-radius: 4px;
  }

  .separator {
    margin-top: 50px;
  }

  .datatable-list-ctrl {
    .ctrl-row {
      color: black;
      @include hex-to-rgba(map-get($colorControlePart, 6), .70);

      &:first-child {
        .ctrl-row__td {
          border: 0;
        }
      }

      &.ctrl {
        &-expertise {
          @include hex-to-rgba(lighten($colorExpertiseTitle, 30), .70);
        }

        &-vaccination {
          @include hex-to-rgba(map-get($colorVaccinationPart, 6), .70);
        }

        &-eval {
          @include hex-to-rgba(map-get($colorEvaluationPart, 6), .70);
        }
      }
    }
  }

  .home-chart {
    &_center {
      display: block;
    }

    &__item {
      margin-bottom: 10px;

      &__legend {
        &:before {
          content: "";
          position: relative;
          left: -10px;
          display: inline-block;
          width: 10px;
          height: 10px;
          border-radius: 2px;
          background-color: map-get($colorControlePart, 6);
        }

        &.ctrl-expertise {
          &:before {
            background-color: lighten($colorExpertiseTitle, 30);
          }
        }

        &.ctrl-at {
          &:before {
            background-color: map-get($colorControlePart, 5);
          }
        }

        &.ctrl-eval {
          &:before {
            background-color: map-get($colorEvaluationPart, 6);
          }
        }

        &.ctrl-vaccination {
          &:before {
            background-color: map-get($colorVaccinationPart, 7);
          }
        }
      }

      .canvas {
        &_full-width {
          width: 100%;
        }
      }
    }
  }

  .act-info-modal {
    &:hover {
      text-decoration: none;

      .info-act-left-item {
        position: relative;
        color: #fff;

        &:before {
          content: "";
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, .2);
          display: block;
          position: absolute;
          border-radius: 90px;
        }
      }
    }
  }

  &.home-user {
    .steps__item {
      &.box {
        .box-header {
          &-load {
            background: white;
          }
        }
      }
    }

    .box-body {
      background: rgba(241, 241, 241, .2);
    }

    .box-header, .panel {
      background: transparent;
    }

    .main-info {
      margin-bottom: 30px;
      position: relative;
    }

    .main-header {
      .navbar {
        background: $colorHeaderUser;

        li > a:hover,
        li > a:active,
        li > a:focus {
          background: darken($colorHeaderUser, 4);
        }
      }
    }

    .nav-tabs-custom-dashboard {
      background: transparent;

      & > .nav-tabs {
        background: transparent;
        border: 0;

        & > li {
          background: #fff;
          margin: 0;

          a {
            &:hover, &:focus, &:active {
              color: inherit;
              background: transparent;
            }
          }

          &.active {
            border-top-color: $colorHeaderUser;
          }

          &:not(.active) {
            a {
              border-bottom: 1px solid $borderTab;
            }
          }
        }
      }
    }

    .user-action {
      &__btn {
        background: $colorBoxHeaderImportant;
        color: #fff;
        width: 100%;
        margin-bottom: 15px;
      }
      &__btn-guide {
        background: $colorBoxHeaderImportant;
        color: #fff;
        width: 50%;
        margin-bottom: 15px;
        margin-top: 18vh;
      }
      &__btn-enquete {
        background: $mainLogoGoldColor;
        color: #fff;
        margin-bottom: 15px;
        font-weight: bold;
      }
    }

    .graph-item {
      &__title {
        color: $colorBoxHeaderImportant;
        margin-bottom: 20px;

        &__type {
          font-weight: 700;
        }
      }
    }

    .info-box {
      color: #fff;
      min-height: 75px;

      &:first-child {

        background: $colorJustifies;

        .icon-custom {
          &:before {
            content: "\f06e";
          }
        }
      }

      &:last-child {
        background: $colorSuspensifs;

        .icon-custom {
          &:before {
            content: "\f071";
          }
        }
      }

      &-content {
        margin-left: 75px;
      }

      &-icon {
        width: 75px;
        height: 75px;
        font-size: 36px;
        line-height: 75px;
      }
    }

    .control-result-donut {
      margin: auto;
    }

    .tab-export {
      &__type {
        margin-bottom: 20px;

        &__item {
          padding: 0 15px;
        }
      }
    }

    .box-header {
      background: $mainLogoDarkColor;
      padding: 0;

      .pointer {
        display: block;
        padding: 10px;
      }
    }

    .fl-form {
      input.fl-input,
      select.fl-select,
      textarea.fl-textarea {
        margin-bottom: 0;
      }

      .btn {
        padding: 5px 15px;
        margin-top: 5px;
      }

      .legend-important{
        margin-top: 10px;
        color: $colorBtnDanger;
      }
    }

    .dataTables_wrapper {
      margin-top: 20px;
    }

    .icheckbox_square-green {
      background: none;
      border: 1px solid grey;
      border-radius: 50px;
      height: 15px;
      width: 15px;
      top: -2px;

      &.checked {
        &:after {
          position: absolute;
          content: '';
          background: $colorBtnSuccess;
          width: 5px;
          height: 5px;
          top: 4px;
          left: 4px;
          border-radius: 50px;
        }
      }
    }

    .box-header {
      min-height: 30px;
      margin-top: 10px;
      padding: 10px;
    }

    .bloc {
      &-tarifs {
        .nav-tabs-custom > .nav-tabs > li {
          border-top: none;
          margin-bottom: 0;
          margin-right: 0;
        }

        .nav-tabs > li {
          margin-bottom: 0;
        }

        .nav-tarifs {
          border-top-color: transparent;
          background-color: $colorBoxHeaderRed;
          color: #ffffff;
          border-top-left-radius: 3px;
          border-top-right-radius: 3px;
          border-bottom-width: 0;

          &__item {
            &__link {
              border-top-color: transparent;
              background-color: $colorBoxHeaderRed;
              color: #ffffff;
              padding-left: 7px;
              padding-right: 7px;

              &:hover {
                border-top-color: transparent;
                background-color: $colorBoxHeaderRed !important;
                color: #ffffff;
                font-weight: bold;
                text-decoration: underline;
              }

              &.noclick {
                font-weight: bold;

                &:hover {
                  color: #ffffff;
                  text-decoration: none;
                }

                &:focus {
                  background-color: transparent;
                }
              }
            }

            &.active {
              border-top-color: transparent;
              color: $mainLogoDarkColor;
              background-color: $colorBoxHeaderRed;

              a {
                &:hover {
                  color: $mainLogoDarkColor;
                  font-weight: normal;
                  text-decoration: none;
                }
              }
            }
          }
        }

        .tab-tarif {
          padding-top: 10px;

          .bloc-tarif {
            display: flex;

            .email-customer-order-form, .generate-order-form {
              display: inline-block;
            }

            .tab-deleted-packs {
              margin-top: 14px;
            }

            .tarif-cm-pack-no-data-left {
              margin: auto;
              text-align: center;
            }

            .tarif-cm-pack-no-data-right {
              margin: auto;
            }
          }
        }

        &.child-collapse {
          .nav-tarifs {
            &__item {
              &.active {
                .nav-tarifs__item__link {
                  border: 0;
                  background: transparent;
                  color: #ffffff;
                }
              }
            }
          }
        }

      }

      &-tarif {
        .radio label, .checkbox label {
          padding-left: 0;
        }

        &__nav {
          &__item {
            color: inherit;
          }
        }
      }
    }
  }
}

@include respond-to(desktop) {
  .home-page {
    .home-chart {
      &_center {
        display: flex;
        align-items: center;
      }

      &__item {
        margin-bottom: 0;
      }
    }

    .form-employee {
      &__btn-edit {
        i {
          margin-top: 30px;
        }
      }

      &__existing-patient {
        background-color: $colorFormEmployee;
      }
    }
  }
}

@include respond-to(large) {
  .home-page {
    .info-act-left-item {
      width: 60px;
      height: 60px;
    }
  }
}

@include respond-to(extra-large) {
  .home-page {
    .info-act-left-item {
      width: 80px;
      height: 80px;
    }
  }
}
